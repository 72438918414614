import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { GlobalStyle } from "../assets/GlobalStyles";
import { GatsbyImage } from "gatsby-plugin-image";
import  PhotoPageLayout  from "../components/PhotoPageLayout"

export default function T(props) {

  
  
  const assets = props.data.allContentfulPortfolioPhotos.nodes
  // console.log(assets)

  // assets.map(({id, image}) => (
  //   console.log(id, image.title, image.gatsbyImageData)
  // ))
    
  return (
    <>
      <GlobalStyle />
      <PhotoPageLayout url={props.path}>
      {
        assets.map(({id, image}) => (
          <StyledImageWrapper key={id}>
          <GatsbyImage 
          image={image.gatsbyImageData}
          alt={image.title}
          />
          </StyledImageWrapper>
        ))}
      </PhotoPageLayout>
    </>
  );
}

const StyledImageWrapper = styled.div`
  margin: 2em;
  @media (max-width: 920px) {
    margin: 1em;
  }
`


export const query = graphql`
query {
  allContentfulPortfolioPhotos(filter: {pageForSortingExTRISH: {eq: "T"}}) {
      nodes {
        id
        image {
          gatsbyImageData
          title
        }
      }
  }
}
`

